import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal, keepNumber } from '@assets/js/regExUtil';

export const tableProperties = [
  {
    label: '是否通知财务',
    prop: 'notice_finance_status',
    itemType: 'select',
    input: true,
    sortable: false,
    options: [
      { value: 0, label: '否' },
      { value: 1, label: '是' }
    ],
    formatter: val => (val === 0 ? '否' : '是')
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '开票金额',
    prop: 'scon_total',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '合同日期',
    prop: 'scon_cndate',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '预计收款日期',
    prop: 'scon_ppdate',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'stff_name', label: '录入人', itemType: 'input', input: true, sortable: false },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    input: true,
    valueFormat: 'timestamp',
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    sortable: false,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const domoSconRequisitionMainFormProperties = [
  {
    label: '销售合同号',
    prop: 'scon_no',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '开票币种',
    prop: 'cust_currency',
    itemType: 'select',
    model: '',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '开票公司抬头',
    prop: 'cptt_id',
    itemType: 'select',
    model: '',
    options: [],
    filterable: true,
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '开票金额',
    prop: 'scon_total',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '预计收款日期',
    prop: 'scon_ppdate',
    model: '',
    itemType: 'date',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '通知日期',
    prop: 'notice_date',
    itemType: 'date',
    model: '',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '合同日期',
    prop: 'scon_cndate',
    model: '',
    itemType: 'date',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '开票类型',
    prop: 'invoice_type',
    itemType: 'select',
    model: '',
    options: [
      { value: '按我司货号开票', label: '按我司货号开票' },
      { value: '按客户货号开票', label: '按客户货号开票' }
    ],
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '发票号码',
    prop: 'invoice_no',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    maxlength: 50,
    show: true,
    input: (list, index, val) => (list[index].model = keepNumber(val))
  },
  {
    label: '开票资料',
    prop: 'invoice_data',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 3,
    split: 24,
    subWidth: 80,
    disabled: false,
    maxlength: 500,
    show: true
  }
];
export const domoSconRequisitionSubTableProperties = [
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票类目',
    prop: 'invoice_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'select',
      options: [
        { value: '宠物用品', label: '宠物用品' },
        { value: '玩具', label: '玩具' },
        { value: '塑料制品', label: '塑料制品' }
      ]
    }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票单价',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 7,
      input: (val, row) => (row.prod_price = keep2Decimal(val))
    }
  },
  {
    label: '开票金额',
    prop: 'prod_total_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'select', options: [] }
  }
];
