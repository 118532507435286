<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="预计收款开始日期"
          end-placeholder="预计收款结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="合同开始日期"
          end-placeholder="合同结束日期"
        />
        <el-button class="vg_ml_8" icon="el-icon-search" size="small" type="primary" @click="getRequisitionsList()"> 查询 </el-button>
        <el-button class="vg_ml_8" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新 </el-button>
      </div>
      <DynamicUTable
        ref="multiTable"
        :need-check-box="false"
        :columns="tableProperties"
        :totalPage="totalPage"
        :need-search="true"
        :table-data="tableData"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getRequisitionsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { domeSconRequisitionAPI } from '@api/modules/domeSconRequisition';
import { tableProperties } from '@/views/FinanceManagement/DomeSconRequisitionManage/requisition';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import UrlEncode from '@assets/js/UrlEncode';
import { setTime } from '@api/public';
import { cloneDeep } from 'lodash';

export default {
  name: 'RequisitionList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      dateRange: [],
      dateRange2: []
    };
  },
  mounted() {
    this.getRequisitionsList();
  },
  methods: {
    initData() {
      this.getRequisitionsList();
    },
    async getRequisitionsList() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange, true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2, true);
      searchForm.create_time = searchForm.create_time ? searchForm.create_time / 1000 : null;
      await domeSconRequisitionAPI.get_domeScons(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getRequisitionsList();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/domescon_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.domescon_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
