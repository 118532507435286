import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const domeSconRequisitionAPI = {
  add_domeScon: params => getNoCatch(`${topic.domeSconRequisitionTopic}/add_domeScon`, params),
  get_domeScons: params => getNoCatch(`${topic.domeSconRequisitionTopic}/get_domeScons`, params),
  get_domeScon_by_id: params => getNoCatch(`${topic.domeSconRequisitionTopic}/get_domeScon_by_id`, params),
  edit_domeScon: params => postNoCatch(`${topic.domeSconRequisitionTopic}/edit_domeScon`, params),
  exportInvPdf: params => getNoCatch(`${topic.domeSconRequisitionTopic}//exportInvPdf`, params)
};
